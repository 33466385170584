import React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { ShapeYellowArrowBanner, ShapeYellowArrowBannerMobile } from '../../Atoms/Icons/Icons';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBanner.scss';
let classNames = require('classnames');

const PageBanner = ({ visuel, scroll, children }) => {
  return (
    <div className="page_banner">
      <div className="visuel">
        <div className="image_mask">
          <div className="mask image_container">
            {visuel &&
              <Img
                className="desktop"
                style={{ width: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
                fluid={visuel}
              />
            }
            {visuel &&
              <Img
                className="mobile"
                style={{ height: '500px', width: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
                fluid={visuel}
              />
            }
          </div>
        </div>
        <ShapeYellowArrowBanner className={classNames('shape_yellow_banner_bottom', 'only_desktop')} />
        <ShapeYellowArrowBannerMobile className={classNames('shape_yellow_banner_bottom', 'only_mobile_tablet')} />
      </div>
      <div className="visuel_overlay">
        {children}
        {
          scroll && (
            <div className="scroll_icon_container">
              <ScrollIcon />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default PageBanner;
