import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../components/Carousel/Carousel';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

let classNames = require('classnames');

const CultureSanteSecurite = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fluid {
            ...GatsbyImageSharpFluid
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { locale } = pageContext;

  const slides = [
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis mauris non elit pretium finibus. Curabitur semper leo nec lorem viverra tristique. Nunc in lacinia libero, eu'
      }
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis mauris non elit pretium finibus. Curabitur semper leo nec lorem viverra tristique. Nunc in lacinia libero, eu'
      }
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis mauris non elit pretium finibus. Curabitur semper leo nec lorem viverra tristique. Nunc in lacinia libero, eu'
      }
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis mauris non elit pretium finibus. Curabitur semper leo nec lorem viverra tristique. Nunc in lacinia libero, eu'
      }
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis mauris non elit pretium finibus. Curabitur semper leo nec lorem viverra tristique. Nunc in lacinia libero, eu'
      }
    }
  ];

  slides.push(...slides);

  const slider_settings = {
    className: 'arrow_bottom_slider',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: false
      }
    }]
  };

  const leftRightBlocks = [
    {
      text: '<p>L’ambition de Colas est le zéro accident. En ce sens, le Groupe assure la santé-sécurité de tous ses collaborateurs en respectant les dispositions légales en vigueur dans les pays où nous opérons. Colas œuvre également à travers des programmes de sensibilisation ad hoc et en mettant à disposition de ses collaborateurs des équipements adaptés aux besoin de chacun.</p><p>De ce fait, en septembre 2020, les « Règles Groupe » (règles de vie et règles qui sauvent) ont été lancées par Frédéric Gardès, PDG de Colas. Elles sont depuis progressivement déployées avec des supports appropriés (films, affiches, méthodes, …) pour en faciliter une compréhension effective et ainsi nous permettre d’atteindre, ensemble, une culture sécurité homogène.</p>',
      title:
        'Développer la culture santé et sécurité pour l’ensemble des parties prenantes',
      visuel: data.file.childImageSharp
    },
    {
      text: '<p>La protection des collaborateurs et la préservation de l’activité du Groupe contre les actes de malveillance est une priorité. Les enjeux se situent sur la prévention et l’anticipation des principaux risques et sur l’accompagnement des situations de crise.</p><p><strong>Les outils à dispositions de la sûreté et la gestion de crise sont les suivants :</strong></p><ul><li><strong>Le Golden Book :</strong> Fiches réflexes très synthétiques recensant les actions essentielles à entreprendre en situation d’urgence.</li><li><strong>Le Plan de Continuité d’Activité (PCA) :</strong> il couvre l’ensemble du personnel, des ressources, des services et des actions clés nécessaires pour gérer le processus de gestion de la continuité des activités.</li><li><strong>Le Manuel de gestion de crise :</strong> il présente les différentes étapes à suivre en cas de crise</li></ul>',
      title: 'Déployer une démarche sûreté et gestion de crise',
      visuel: data.file.childImageSharp
    },
  ];

  return (
    <Layout>
      <Seo
        title="Colas Engagement Culture Sante Securite"
        description="Culture de la sante et de la securite pour proteger"
      />
      <div
        className={classNames(
          'page_template',
          'zero_bottom_padding',
          'page_engagement_culture_sante_securite'
        )}
      >
        <ScrollPageNav
          data={[
            {
              title: 'Introduction',
              section: 'block_0_section'
            },
            {
              title: 'hide',
              section: 'block_1_section'
            },
            {
              title: 'Nos réalisations',
              section: 'block_2_section'
            },
            {
              title: 'Chiffres clés',
              section: 'block_3_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'engagement', url: '/engagements' },
                currentPage: {
                  title: 'Culture santé sécurité pour protéger',
                  url: '/culture',
                },
              }}
            />
            <TitlePage
              color="color_white"
              title="CULTURE SANTÉ SÉCURITÉ POUR PROTÉGER"
            />
          </div>
        </PageBanner>
        <IntroPage
          customClass="block_0_section"
          title=""
          subtitle=""
          visuel={data.file.childImageSharp}
          text="<p>Colas met un point d’honneur sur le fait de valoriser le capital humain. Nous veillons activement à ce l’ensemble de nos collaborateurs bénéficie de conditions de travail exemplaires, dans lesquelles les risques sur leur santé et leur sécurité sont minimisés. De même, la sureté et la gestion de crise contribuent à la préservation générale de nos collaborateurs et de l’activité du Groupe</p>"
          size="border" //wrapper
        />
        <section className={classNames('section_content', 'block_1_section')}>
          <div className="wrapper_page_xs">
            {leftRightBlocks.map((block, i) => (
              <div key={i}>
                <TextVisuelTwoCol
                  title={block.title}
                  orderInverse={(i % 2 == 0) ? true : false}
                  visuel={block.visuel}
                  extactHalf={(i % 2 == 0) ? false : true}
                  alignLeft={(i % 2 == 0) ? true : false}
                  alignTop={true}
                  text={block.text}
                  colorDescription="color_black"
                  textFullWidth={(i % 2 == 0) ? false : true}
                  smallVisuel={(i % 2 == 0) ? false : true}
                  extremeRight={(i % 2 == 0) ? true : false}
                  visuelAspectRatioMobile={1.34241245}
                  visuelAspectRatioDesktop={(i % 2 == 0) ? 1.04107143 : 0.699801193}
                  visuelMaxWidthDesktop={(i % 2 == 0) ? '100%' : 352}
                />
              </div>
            ))}
          </div>
        </section>
        <section className={classNames('section_content', 'block_2_section')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className={classNames('wrapper_page_xs')}>
              <TitleSection
                title="Nos réalisations"
                subtitle="Les actualités du groupe Colas, à travers le monde"
                type="arrow"
              />
            </div>
            <div className={classNames('wrapper_page_xs', 'only_desktop')}>
              <Carousel slides={slides} sliderMode="title_description" />
            </div>
            <div className={classNames('only_mobile_tablet')}>
              <SliderComponent settings={slider_settings}>
                {slides.map((slide, i) => (
                  <div key={i}>
                    <div className="only_mobile">
                      {slide?.image &&
                        <Img
                          style={{ width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                        />
                      }
                    </div>
                    <div className="only_tablet">
                      {slide?.image &&
                        <Img
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.desktop, aspectRatio: 1 }}
                        />
                      }
                    </div>
                    <div className="info_title_description">
                      <p>{slide.info.text}</p>
                      {slide.info.link && (
                        <Button
                          primary={true}
                          label={slide.info.link_label}
                          link={slide.info.link}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </SliderComponent>
            </div>
          </ShapeBackground>
        </section>
        <KeyNumbers
          title="Chiffre Clés"
          textColor="white"
          sectionName="block_3_section"
          NoPadding={true}
          locale={pageContext}
          datas={[
            {
              number: '6.12',
              title: '<p>Taux de fréquence des accidents du travail</p>',
            },
            {
              number: '0.043',
              title:
                '<p>Taux de fréquence des accidents de la route impliquant un véhicule colas</p>',
            },
          ]}
        />
      </div>
    </Layout>
  );
};

export default CultureSanteSecurite;
