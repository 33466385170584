import * as React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import DefaultImage from '../../../assets/images/history.png';
import { Button } from '../../../Molecules/Button/Button';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './TextVisuelTwoCol.scss';

let classNames = require('classnames');

const TextVisuelTwoCol = ({
  key,
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  colorTitle,
  colorDescription,
  title,
  text,
  textList,
  titleBackground,
  titleHash,
  extactHalf,
  alignLeft,
  textFullWidth,
  smallVisuel,
  alignTop,
  orderInverse,
  visuelAspectRatioMobile,
  visuelAspectRatioDesktop,
  visuelMaxHeightDesktop,
  visuelMaxWidthDesktop,
  cta
}) => {
  const { ctaText, ctaLink } = cta || {};

  const image_sources = [
    {
      ...visuel?.fluid,
      media: '(max-width: 768px)',
      aspectRatio: visuelAspectRatioMobile || 0.856521739,
      key: 'mobile'
    },
    {
      ...visuel?.fluid,
      media: '(min-width: 768px)',
      aspectRatio: visuelAspectRatioDesktop || 1.542997543,
      maxHeight: visuelMaxHeightDesktop || '100%',
      maxWidth: visuelMaxWidthDesktop || '100%',
      key: 'desktop'
    },
  ];

  return (
    <div className={classNames('text_visuel_two_col')}>
      <div className={classNames({
        'cross_wrapper_extreme_right': extremeRight,
        'cross_wrapper_extreme_left': extremeLeft
      })}>
        <div className={classNames('text_visuel', { 'order_inverse': orderInverse }, { 'align_top': textList || alignTop })}>
          <div className={classNames('visuel', { 'half': extactHalf }, { 'small_visuel': smallVisuel })}>
            {visuel &&
              <Img
                style={{ height: '100%', width: '100%' }}
                fluid={image_sources}
                alt={alt}
              />
            }
            {!visuel && DefaultImage &&
              <img src={DefaultImage} alt={alt} />
            }
          </div>
          <div className={classNames('text_container', { 'right_flex': extactHalf && !orderInverse }, { 'left_flex': alignLeft }, { 'textFullWidth': textFullWidth }, { 'small_padding': smallVisuel })}>
            <div className={classNames('text_center')}>
              {title && !titleHash &&
                <TitleBlock
                  title={title}
                  titleBackground={titleBackground}
                  color={colorTitle}
                />
              }
              {titleHash &&
                <div className="title_hash">{title}</div>
              }
              {text &&
                <div className={colorDescription} dangerouslySetInnerHTML={{ __html: text }}></div>
              }
              {textList &&
                <ul className={colorDescription}>
                  {textList.map((textItem, index) => (
                    <li key={index}>
                      {textItem.text}
                    </li>
                  ))}
                </ul>
              }
              {ctaText && ctaLink &&
                <Button
                  label={ctaText}
                  link={ctaLink}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TextVisuelTwoCol.defaultProps = {
  text: '',
  alt: '',
  text_color: 'color_dark_bleu',
  titleBackground: false
};

TextVisuelTwoCol.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool,
  cta: PropTypes.object,
  // textList: PropTypes.array,
};

export default TextVisuelTwoCol;
